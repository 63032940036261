// config
@import "variables";

//layout
@import "reboot";

//mixin
@import "mixin";

// animation
@import "animation";

// button
@import "../components/Button/style";

// button Dropdown
@import "../components/ButtonDropdown/style";

// side menu
@import "../components/SideMenu/style";

// help panel
@import "../components/HelpPanel/style";

// page panel
@import "../components/PagePanel/style";

// header panel
@import "../components/HeaderPanel/style";

// create step
@import "../components/CreateStep/style";

// tiles
@import "../components/Tiles/style";

// alert
@import "../components/Alert/style";

// table panel
@import "../components/TablePanel/style";

// s3 form item
@import "../components/FormItem/style";

// autocomplete
@import "../components/AutoComplete/style";

// text input
@import "../components/TextInput/style";

// text area
@import "../components/TextArea/style";

// external link
@import "../components/ExtLink/style";

// status
@import "../components/Status/style";

//v alue with label
@import "../components/ValueWithLabel/style";

// tab
@import "../components/Tab/style";

// copy text
@import "../components/CopyText/style";

// copy button
@import "../components/CopyButton/style";

// loading text
@import "../components/LoadingText/style";

// select
@import "../components/Select/style";

// multiple select
@import "../components/MultiSelect/style";

// tag filter
@import "../components/TagFilter/style";

// tag list
@import "../components/TagList/style";

// Info Span
@import "../components/InfoSpan/style";

// Switch
@import "../components/Switch/style";

// Status Indicator
@import "../components/StatusIndicator/style";

// ExpandableSection Section
@import "../components/ExpandableSection/style";

// //upload
// @import "../components/Upload/style";

// //progress
// @import "../components/Progress/style";

#root {
  white-space: pre-line;
}

.page-loading {
  text-align: center;
  padding: 20px;
}

.select-no-data {
  padding: 10px;
  color: #999;
}

.code {
  background-color: #eee;
  padding: 10px;
}

.hide {
  display: none !important;
}

.cp {
  cursor: pointer;
}

.no-data {
  padding: 20px;
  color: #888;
}

.sign-out {
  &:hover {
    text-decoration: underline;
  }
}

.json-format {
  max-height: 400px;
  overflow: auto;
  padding: 10px;
  border: 1px solid #ccc;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pl-10 {
  padding-left: 10px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-m10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-3 {
  padding-top: 3px;
}

.pr-5 {
  padding-right: 5px;
}

.flex-1 {
  flex: 1;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.border-left-c {
  border-left: 1px solid #eaeded;
}

.pd-10 {
  padding: 10px;
}

.pd-20 {
  padding: 20px;
}

.ptb-10 {
  padding: 10px 0;
}

.plr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.font-bold {
  font-weight: bold;
}

.pr {
  position: relative;
}

.pb-20 {
  padding-bottom: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-100 {
  padding-bottom: 100px;
}

.reverse {
  transform: rotate(-180deg);
}

.reverse-90 {
  transform: rotate(-90deg);
}

.space-between {
  justify-content: space-between;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.inline-block {
  display: inline-block !important;
}

.m-w-800 {
  max-width: 800px;
}

.m-w-75p {
  max-width: 75%;
}

.m-w-35p {
  max-width: 35%;
}

.m-w-45p {
  max-width: 45%;
}

.m-w-1024 {
  max-width: 1024px;
}

.bread-crumb {
  padding: 10px 0 15px 0;
  a {
    color: $blue !important;
  }
}

.link {
  color: #0073bb;
  text-decoration: none;
  cursor: pointer;
}

.button-action {
  padding-bottom: 40px;
  &.no-pb {
    padding-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
}

.lh-header {
  height: 40px;
  line-height: 40px;
  background-color: $black-bg;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .logo {
    padding-left: 15px;
    font-weight: 600;
  }
  .user {
    padding-right: 15px;
  }
}

.radio-title {
  padding-bottom: 2px;
}
.radio-desc {
  font-size: 12px;
  color: #687078;
  line-height: 16px;
}

.lh-main {
  flex: 1;
  position: relative;
  overflow: hidden;
  &-content {
    display: flex;
    height: 100vh;
    padding-bottom: 70px;
    flex-direction: row;
    .lh-container {
      flex: 1;
      background-color: #f2f3f3;
      overflow: auto;
      .lh-content {
        padding: 10px 40px;
      }
    }
  }
}

.lh-footer {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  background-color: $black-bg;
  color: #fff;
}

.service-item-list {
  display: flex;
  flex-wrap: wrap;
  .service-item {
    width: 198px;
    height: 134px;
    border: 1px solid #aab7b8;
    margin: 0 20px 20px 0;
    cursor: pointer;
    .name {
      padding: 10px 0 10px 20px;
    }
    .image {
      padding: 10px 0;
      text-align: center;
    }
    &.active {
      background-color: #f1faff;
      border: 1px solid #0073bb;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.lh-import-cluster {
  .create-wrapper {
    display: flex;
    .create-content {
      padding-top: 10px;
      flex: 1;
    }
  }
}

.lh-create-log {
  .create-wrapper {
    display: flex;
    .create-content {
      padding-top: 10px;
      flex: 1;
    }
  }
}

.pop-over {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 5px 10px;
  margin: -12px 0 0 -8px;
  color: green;
  font-weight: 500;
}

.ingest-desc-title {
  font-size: 18px;
  padding: 1px 0;
}

.ingest-desc-desc {
  color: #666;
  font-size: 14px;
  padding: 3px 0;
}

.img-border {
  border: 1px solid #ccc;
}

.no-more-data {
  margin: 20px auto 0;
  text-align: center;
  font-size: 16px;
  color: #999;
}

.show-tag-list {
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  align-items: center;
  &.top-header {
    background: #fafafa;
  }
  .min-height {
    min-height: 30px;
  }
  &.flex-start {
    align-items: flex-start;
  }
  &:nth-of-type(odd) {
    background: #fafafa;
  }
  &.has-border-bottom {
    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
  &:last-child {
    border-bottom: 0;
  }
  &.no-stripe {
    &:nth-of-type(odd) {
      background: #fff;
    }
  }
  .checkbox {
    width: 30px;
    text-align: center;
  }
  .tag-key {
    min-width: 400px;
    &.log {
      min-width: 220px;
      width: 220px;
    }
    padding-left: 10px;
    &.w-alarm {
      min-width: 800px;
    }
  }
  .tag-value {
    word-break: break-all;
  }
}

.cf-check-title {
  padding: 5px 0 10px 0;
  .item {
    input {
      margin-right: 5px;
    }
  }
}

.cf-check-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 24%;
    input {
      margin-right: 5px;
    }
  }
}

.log-detail-specs {
  width: 100%;
  color: #666;
  th {
    border-top: 1px solid #eaeded;
    border-bottom: 1px solid #eaeded;
  }
  th,
  td {
    padding: 8px 10px;
    border-bottom: 1px solid #eaeded;
  }
}

.clo-login-title {
  color: #444;
  font-size: 24px;
  padding: 0 0 5px 0;
  text-align: center;
}

.clo-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.oidc-login {
  min-height: 40vh; // height of the browser viewport
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #666;
  }
}

.modal-content {
  padding: 20px;
  min-height: 50px;
  font-size: 16px;
  // max-width: 520px;
  line-height: 1.5em;
  &.alert-content {
    max-width: 620px;
    padding: 10px;
  }
}

.page-bottom {
  padding: 0 20px 0 0px;
  text-align: left;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  background-color: #232f3e;
  position: relative;
  .item {
    display: inline-block;
    margin-left: 25px;
    font-size: 14px;
    color: #fff;
    padding-left: 23px;
    cursor: pointer;
  }
  .bottom-icon {
    position: absolute;
    margin: 5px 0 0 -22px;
  }
  .feedback {
    float: left;
  }
  .language {
    float: left;

    .language-select {
      color: #444444;
      position: absolute;
      margin-left: -20px;
      bottom: 30px;
      background-color: #ffffff;
      border: 1px solid #ccc;
      // box-shadow: 0px 2px 2px #ccc;
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      li {
        padding: 2px 15px;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
  .notice {
    float: right;
    color: #aab7b8;
    border-left: 0;
    cursor: pointer;
  }
  .privacy {
    float: right;
    font-weight: regular;
    color: #eee;
    font-size: 12px;
    padding: 0 1em;
    cursor: pointer;
    &.no-pointer {
      cursor: default;
    }
  }
}

.select-loading {
  position: absolute;
  left: 40%;
  margin-top: -26px;
}

.cross-account,
.syslog-guide {
  .deploy-desc {
    padding: 3px 0;
  }
  .deploy-steps {
    padding-left: 15px;
    line-height: 1.8em;
    .step-desc {
      padding: 2px 0 0 15px;
    }
  }
  .guide-code {
    background-color: #eee;
    color: #666;
    padding: 4px 4px;
    border-radius: 3px;
  }
}

.config-list {
  padding: 5px 0 5px 10px;
  .list-item {
    padding: 2px 0;
    .label {
      padding: 2px 0 0 0;
    }
    input {
      margin: 0 5px 0 0;
    }
  }
}

.applog-buffer {
  padding: 3px 0;
  label {
    font-size: 16px;
    input {
      margin-right: 5px;
    }
    .desc {
      font-size: 14px;
      color: #666;
    }
  }
}

.instance-group-select {
  background-color: #fff;
  font-size: 16px;
  padding: 20px 20px 0;
  .group-select-item {
    padding: 5px 0;
  }
}

/**material ui*/
.MuiDialog-paperWidthMd {
  max-width: 1200px !important;
}

.PrivateSwitchBase-root-1 {
  padding: 0 !important;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiAutocomplete-listbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #f1faff !important;
}

.MuiSelect-select.MuiSelect-select {
  display: flex;
}

.select-item-status {
  font-size: 14px;
  .icon {
    position: absolute;
    margin: -1px 0 0 -22px;
  }
  &.green {
    color: green;
  }
  &.gray {
    color: #666;
  }
  &.red {
    color: red;
  }
}

.invalid {
  .gsui-autocomplete-select input,
  .gsui-textinput input,
  .MuiSelect-select {
    // color: $error !important;
    border: 1px solid $error !important;
    border-left: 4px solid $error !important;
  }
  table {
    border: 1px solid $error !important;
    border-left: 4px solid $error !important;
  }

  .input-icon {
    color: $error !important;
  }
}

.swal2-container {
  z-index: 1999 !important;
}

.maxmind-copyright {
  font-size: 12px;
  padding: 30px 0 30px 4px;
  color: #888;
}

.chart-mask {
  position: absolute;
  z-index: 99;
  background-color: rgba($color: #fff, $alpha: 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monitor-chart-list {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5%;

  .monitor-chart {
    width: 33%;
    margin-top: 10px;
    padding: 10px 10px 20px;
    box-shadow: 1px 2px 4px #ddd;
    .chart-unit {
      font-weight: 700;
      font-size: 12px;
      position: absolute;
      margin: 25px 0 0 10px;
    }
  }
}

.modal-time-range {
  position: relative;
  padding-top: 20px;
  .time-select-content {
    position: absolute;
    top: -10px;
    right: 0;
    width: 448px;
    z-index: 9999;
  }
}

.last-update-time {
  font-weight: normal;
  color: #00446f;
}

.specify-time {
  margin-left: 10px;
  margin-right: 0px;
  border: 1px solid #aab7b8;
  background-color: #fff;
  position: relative;

  .item {
    background-color: #fff;
    display: inline-block;
    padding: 8px 20px;

    cursor: pointer;
    &:active {
      background-color: #eee;
    }
    &.item-active {
      font-weight: bold;
      color: #0073bb;
    }
    &.small {
      padding: 4px 20px 5px;
      &.custom {
        margin-top: 30px;
      }
    }
    &.custom {
      position: absolute;
      background-color: #fff;
      border: 1px solid #aab7b8;
      margin-top: 36px;
      padding: 2px 0;
      right: 0;
      .time-range-picker {
        position: relative;
        z-index: 99;
        padding: 0px;
        .react-minimal-datetime-range__range-input-wrapper {
          border-radius: 0;
          border: 0;
        }
        .react-minimal-datetime-range {
          width: 540px;
          right: 0;
          margin-top: 4px;
        }
        .react-minimal-datetime-date-piker {
          text-align: right;
        }

        // .react-minimal-datetime-range-calendar__table-cel.no-border,
        // .react-minimal-datetime-range-calendar__table-cel,
        .react-minimal-datetime-range-calendar__table-cel.react-minimal-datetime-range-calendar__date-item:not(
            .disabled
          ) {
          padding: 10px;
        }

        .react-minimal-datetime-range__button {
          border-radius: 0px;
          cursor: pointer;
          display: inline-block;
          font-size: 12px;
          margin-right: 10px;
          padding: 8px 15px;
          color: #ec7211;
        }
        .react-minimal-datetime-range__button--confirm {
          background: #ec7211;
          border: 1px solid #ec7211;
          color: #fff;
        }
      }
    }
  }
}

.panel-content {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #eaeded;
  align-items: center;
  justify-content: space-between;
}

.alarm-preset-gray-bg {
  background-color: #f1f1f1;
  padding: 10px;
}

.preset-alarm-list {
  padding: 0px 20px 0 20px;
  p {
    color: #666;
    margin: 0;
    padding: 12px;
    line-height: 1em;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.log-table {
  margin: 10px 0 100px;
  &.box-shadow {
    background-color: #ffffff;
    box-shadow: 0px 2px 2px #ccc;
  }
  .header {
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 15px 20px 15px 20px;
    .header-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .big-title {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 15px 0 15px 20px;
  }
  table {
    width: 100%;
    a {
      color: #0073bb;
      cursor: pointer;
      &:hover {
        color: #0073bb;
        text-decoration: underline;
      }
    }
    th,
    td {
      text-align: left;
      padding: 8px 20px;
      border-bottom: 1px solid #eaeded;
    }
    tr:nth-child(even) > td,
    .tr:nth-child(even) > th {
      background: rgba(0, 0, 0, 0.05); // Choose your own color here
    }
    th {
      background-color: #fafafa;
      border-top: 1px solid #eaeded;
      border-bottom: 1px solid #eaeded;
    }
  }
}

.load-more-wrap {
  text-align: center;
  .load-more {
    cursor: pointer;
    color: #0073bb;
  }
}
