.gsui-alert-wrap {
  border: 1px solid #0073bb;
  background-color: #f1faff;
  padding: 12px 20px;
  .normal-container {
    display: flex;
    align-items: flex-start;
  }
  &.margin {
    margin: 0 0 15px 0;
  }
  &.warning {
    border: 1px solid #aab7b8;
    background-color: #ffffff;
  }
  &.error {
    border: 1px solid #d13212;
    background-color: #fdf3f1;
  }
  &.pass {
    .text {
      &-content {
        color: #008000;
      }
    }
  }
  &.inprogress {
    border: 1px solid #0073bb;
    background-color: #0073bb;
    .text {
      &-title {
        color: #fff;
      }
      &-content {
        color: #fff;
      }
    }
  }
  .red {
    color: #d13212;
  }
  .icon {
    color: #0073bb;
    width: 30px;
    .error-text {
      color: $error;
    }
  }
  .text {
    padding-left: 5px;
    flex: 1;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    &-content {
      padding-top: 2px;
      font-size: $font-size-sm;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
  .wrap-line {
    word-break: break-word !important;
    white-space: break-spaces;
  }
  .alert-confirm-check {
    padding-left: 35px;
    margin-top: 15px;
  }
}
